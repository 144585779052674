import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { ChangeEvent } from 'react';
import { PageProps } from 'gatsby';

type SearchResponse = {
  hits: {
    hits: [
      {
        _index: string,
        _type: string,
        _id: string,
        _score: string,
        _source: {
          id: string,
          title: string,
          category: [string],
          description: string,
          source: string,
          sourceWebsite: string,
          link: string,
          pubDate: string
        }
      }
    ]
  }
};

const TestSearch: React.FC<PageProps> = ({ location }) => {
  const siteTitle = 'Test Search Index';
  const [results, setResults] = useState<SearchResponse>(null);
  const [searchIndex, setSearchIndex] = useState('blogs');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (searchText) {
      const spinner = document.getElementById('spinner');
      spinner.hidden = false;
      const searchUrl = `${process.env.SEARCH_INDEX_HOST}/${searchIndex}/_search?size=1000&q=${searchText}`;
      fetch(searchUrl)
        .then((resp) => resp.json())
        .then((resp: SearchResponse) => setResults(resp))
        .then(() => {
          spinner.hidden = true;
        });
    } else {
      setResults(null);
    }
  }, [searchText, searchIndex]);

  const renderedResults = results?.hits?.hits?.map((record) => {
    return (
      <Row>
        <Col><a href={record._source.link} target={'_blank'}>{record._source.title}</a></Col>
        <Col>{record._source.description}</Col>
        {searchIndex === 'blogs' &&
          <Col><a href={record._source.sourceWebsite} target={'_blank'}>{record._source.source || 'Source'}</a></Col>
        }
        <Col>{(new Date(record._source.pubDate)).toDateString()}</Col>
      </Row>)
  });
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="TestSearch" />
      <Container fluid={true}>
        <Row>
          <Col>
            <Form.Control type="text" placeholder="Search Text" onChange={(evt) => setSearchText(evt.target.value)} />
          </Col>
          <Col>
            <Form.Check
              inline
              type={'radio'}
              id={`blogs-toggle`}
              label={`Disaster Articles`}
              onClick={() => setSearchIndex('blogs')}
              checked={searchIndex === 'blogs'}
            />
            <Form.Check
              inline
              type={'radio'}
              id={`research-gate-toggle`}
              label={`Research Gate`}
              onClick={() => setSearchIndex('research-gate')}
              checked={searchIndex === 'research-gate'}
            />
            <Form.Check
              inline
              type={'radio'}
              id={`conservation-blogs-toggle`}
              label={`Conservation Map Articles`}
              onClick={() => setSearchIndex('conservation-blogs')}
              checked={searchIndex === 'conservation-blogs'}
            />
          </Col>
          <Col>
            <Spinner animation="border" id={'spinner'} hidden={true} />
          </Col>
        </Row>
        <Row>
          <Col>Title</Col>
          <Col>Description</Col>
          {searchIndex === 'blogs' && <Col>Source</Col>}
          <Col>Date</Col>
        </Row>
        {renderedResults}
      </Container>
    </Layout>
  );
};

export default TestSearch;
